import BrClubElite from "../../components/BrClubElite";
import Footer from "../../components/Footer";
import Gym from "../../components/Gym";
import Hero from "../../components/Hero";
import OpeningTimes from "../../components/OpeningTimes";
import TrainLikeChampion from "../../components/TrainLikeChampion";
import WorldClassTraining from "../../components/WorldClassTraining";
import Navbar from "../../components/navbar";
const Home = () => {
  return (
    <main>
      <Navbar />
      <Hero />
      <TrainLikeChampion />
      <BrClubElite />
      <OpeningTimes />
      <WorldClassTraining />
      <Gym />
      <Footer />
    </main>
  );
};

export default Home;
