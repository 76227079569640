import styles from "./index.module.scss";
import worldClass from "../../assets/videos/world-class.mp4";
import specialOffers from "../../assets/icons/special-offers.svg";
import whiteDownArrow from "../../assets/icons/white-down-arrow.svg";
import membership from "../../assets/icons/membership.svg";
import RegisterButton from "../RegisterButton";

const WorldClassTraining = () => {
  const content = [
    {
      icon: membership,
      title: "membership available",
      text: "From July 1st - Sept 30\nEVERY DAY FROM 7 AM - 8 PM\nADDRESS BEACH & VIDA AL ALAMEIN",
    },
    {
      icon: specialOffers,
      title: "Group Sessions",
      text: "1 Day Pass (EGP600 - $12) \n 3 Day Pass (EGP4,000 - $80) \n One Week (EGP20,000 - $400)",
    },{
      icon: specialOffers,
      title: "Personal training Memberss",
      text: "1 session (EGP3,000 - $60) \n 3 Day Pass (EGP4,000 - $80) \n One Week (EGP20,000 - $400)",
    },
  ];
  return (
    <section className={styles["world-class-wrapper"]}>
      <div className={styles["world-class-wrapper-vertical-row"]}>
        <RegisterButton
          stylingClassName={
            styles["world-class-wrapper-vertical-row-register-btn"]
          }
        >
          <p>register now</p> <img src={whiteDownArrow} alt="" />
        </RegisterButton>{" "}
      </div>
      <div className="yellow-overlay" />
      <video loop autoPlay muted>
        <source src={worldClass} type="video/mp4" />
      </video>
      <div className={styles["world-class-wrapper-content"]}>
        <p>summer program</p>
        <h3>membership and PRICING</h3>
        <div className={styles["world-class-wrapper-content-list"]}>
          {content.map((item, index) => (
            <div
              className={styles["world-class-wrapper-content-list-item"]}
              key={index}
            >
              <img src={item.icon} alt={item.title} />
              <div
                className={styles["world-class-wrapper-content-list-item-info"]}
              >
                <h6>{item.title}</h6>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WorldClassTraining;
