import styles from "./index.module.scss";
import logo from "../../assets/icons/logo.svg";
import footerBig from "../../assets/images/footer-big.png";
import footerSmall from "../../assets/images/footer-small.png";

const Footer = () => {
  return (
    <footer className={styles["footer-wrapper"]}>
      <img src={logo} className={styles["footer-wrapper-logo"]} />
      <div className={styles["footer-wrapper-vertical-row"]}>
        <hr className={styles["footer-wrapper-vertical-row-line"]} />
        <div className={`circle ${styles["circle"]}`} />
        <a href="/#summer-program" className={styles["btn"]}>Summer Program</a>
        <div className={`circle ${styles["circle"]}`} />
        <a href="/about-big-ramy" className={styles["btn"]}>about big ramy</a>
      </div>
      <div className={styles["footer-wrapper-content"]}>
        <div className={styles["footer-wrapper-content-with-copyrights"]}>
          <div
            className={styles["footer-wrapper-content-with-copyrights-info"]}
          >
            <h1>Stay Connected with BIG RAMY Club Elite</h1>
            <div
              className={
                styles["footer-wrapper-content-with-copyrights-info-social"]
              }
            >
              <a href="https://www.facebook.com/profile.php?id=61561571980805" target="_blank">facebook</a>
              <div
                className={`circle ${styles["circle"]}`}
              />
              <a href="https://www.instagram.com/br.clubelite/?hl=en" target="_blank">instagram</a>
              {/* <div
                className={`circle ${styles["circle"]}`}
              /> */}
              {/* <a href="/">linkedin</a> */}
            </div>
            <div
              className={
                styles[
                "footer-wrapper-content-with-copyrights-info-find-contact"
                ]
              }
            >
              <div
                className={
                  styles[
                  "footer-wrapper-content-with-copyrights-info-find-contact-item"
                  ]
                }
              >
                <h6>find us</h6>
                <p><a href='https://maps.app.goo.gl/7Z56oGqurCQcPx629?g_st=iw'> Address Beach Resort
                  <br />  Marassi, Egypt</a></p>
              </div>
              {/* <div
                className={
                  styles[
                    "footer-wrapper-content-with-copyrights-info-find-contact-item"
                  ]
                }
              >
                <h6>find us</h6>
                <p>9, Al Abageyah, El Mokattam, Cairo Governorate 4414133</p>
              </div> */}
              <div
                className={
                  styles[
                  "footer-wrapper-content-with-copyrights-info-find-contact-item"
                  ]
                }
              >
                <h6>contact us</h6>
                <p>
                  info@br-club.com
                </p>
              </div>
            </div>
          </div>
          <div
            className={
              styles["footer-wrapper-content-with-copyrights-copyrights"]
            }
          >
            <p>All copyrights reserved (C) 2024</p>
            <p>
              MADE WITH PASSION BY <a href="https://uniparticle.com" target="_blank">UNIPARTICLE</a>
            </p>
          </div>
        </div>
        <img
          src={footerBig}
          className={styles["footer-wrapper-content-image-big"]}
          alt="footer"
        />
        <img
          src={footerSmall}
          className={styles["footer-wrapper-content-image-small"]}
          alt="footer"
        />
        <div className={styles["footer-wrapper-content-copyrights-container"]}>
          <p>All copyrights reserved (C) 2024</p>
          <p>MADE WITH PASSION BY <a href="https://uniparticle.com" target="_blank"> UNIPARTICLE</a></p>
          <div
            className={
              styles["footer-wrapper-content-copyrights-container-white"]
            }
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
