import styles from "./index.module.scss";
import trainVideo from "../../assets/videos/train-video.mp4";
import dumpbell from "../../assets/icons/dumpbell.svg";
import beach from "../../assets/icons/beach.svg";
import rightArrow from "../../assets/icons/right-arrow.svg";
import bigRamy from "../../assets/images/big-ramy.png";
import RegisterButton from "../RegisterButton";

const TrainLikeChampion = () => {
  const features = [
    {
      icon: dumpbell,
      text: "Equipped with state-of-the-art machines, free weights, and dedicated spaces for cardio and strength training, you’ll find everything you need to maintain your fitness routine.",
    },
    {
      icon: beach,
      text: "Enjoy the stunning beach views while you work out, adding an extra layer of motivation and relaxation to your exercise regime.",
    },
  ];
  return (
    <section className={styles["train-wrapper"]} id="program">
      <div className="yellow-overlay" />
      <video loop autoPlay muted>
        <source src={trainVideo} type="video/mp4" />
      </video>
      <div className={styles["train-wrapper-content"]}>
        <div className={styles["train-wrapper-content-line-row"]}>
          <hr className={styles["train-wrapper-content-line-row-line"]} />
          <div
            className={`circle ${styles["train-wrapper-content-line-row-circle"]}`}
          />
        </div>
        <div className={styles["train-wrapper-content-texts"]}>
          <h1>
            If you’re looking to keep in shape during your break at Big Ramy
            Club Elite then look no further!
          </h1>
          <div className={styles["train-wrapper-content-texts-features-container"]}>
            {features.map((item, index) => (
              <div
                key={index}
                className={
                  styles["train-wrapper-content-texts-features-container-feature"]
                }
              >
                <img src={item.icon} alt={"dumpbell"} />
                <p>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles["train-wrapper-resgister-btn-container"]}>
        <RegisterButton
          stylingClassName={styles["train-wrapper-resgister-btn-container-btn"]}
        >
          <p>register now</p> <img src={rightArrow} alt="" />
        </RegisterButton>
      </div>
      {/* <img src={bigRamy} alt="big ramy" /> */}
    </section>
  );
};

export default TrainLikeChampion;
