import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import brVideo from '../../assets/videos/about-big-ramy.mp4';
import Navbar from '../../components/navbar';
import Footer from '../../components/Footer';
export default function AboutBigRamy({ setSnackbar }) {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles["about"]} >
      <Navbar></Navbar>
      <Helmet>
        <title>BRCLUB | ABOUT BIG RAMY</title>
      </Helmet>
      <div className={styles["container"]}>
        <div className={styles["container-col"]}>
          <video loop autoPlay muted> <source src={brVideo}></source></video>
        </div>
        <div className={styles["container-text"]} >
          <div className={styles["header"]} >
            <span>ABOUT BIG RAMY</span>
            <h1>Conquering the Mr. Olympia Stage with Cutting-Edge Training and Nutrition Strategies</h1>
          </div>
          <p>Big Ramy, born Mamdouh Elssbiay on September 16, 1984, in Egypt, is a legendary bodybuilder known for his remarkable achievements in the world of professional bodybuilding. Throughout his career, Ramy has transformed his physique, honed his skills, and utilized the latest scientific advancements in training and nutrition to reach the pinnacle of success. With an unwavering determination, he has worked with esteemed coaches such as Badr Boday, Dennise James, George Farah, Kriss Assito, and ultimately, Chad Nicolaos, to claim the prestigious Mr. Olympia title.</p>
        </div>

      </div>
      <div className={styles["container-details"]} >
        <article>
          <div className={styles["header"]} >
            <span className={styles["black"]}>Mr. Olympia Triumph</span>
            <h2>Big Ramy's relentless pursuit of excellence culminated in his crowning achievement at the 202X Mr</h2>
          </div>
          <p>Big Ramy's relentless pursuit of excellence culminated in his crowning achievement at the 202X Mr. Olympia competition. With Chad Nicolaos by his side, Ramy showcased his sculpted muscles, jaw-dropping size, and impeccable conditioning, leaving judges and fans in awe.</p>
          <p>Ramy's victory at the Mr. Olympia showcased the culmination of years of hard work, dedication, and the groundbreaking training and nutrition strategies he had employed throughout his journey. His triumph solidified his status as one of the most influential and dominant figures in the world of professional bodybuilding.</p>

          <p>Big Ramy's remarkable journey from Egypt to conquering the Mr. Olympia stage is a testament to his unwavering commitment, relentless work ethic, and utilization of cutting-edge training and nutrition techniques. Collaborating with esteemed coaches, Ramy embraced the latest scientific advancements in bodybuilding to sculpt his physique to perfection and claim the ultimate title in the sport.</p>
          <p>Today, Big Ramy's story continues to inspire aspiring bodybuilders worldwide, motivating them to push their limits, explore innovative approaches, and harness the power of science to achieve their goals. With his legacy firmly etched in the annals of bodybuilding history, Ramy stands as a true icon of the sport, leaving an indelible mark on the minds and bodies of future generations.</p>
        </article>
      </div>

      <Footer></Footer>

    </div>
  )
}
