import styles from "./index.module.scss";
import gym from "../../assets/images/gym.jpg";

const Gym = () => {
  return (
    <section className={styles["gym-picture-wrapper"]}>
      <img src={gym} />
      <div className={styles["gym-picture-wrapper-vertical-row"]}>
        <div
          className={styles["gym-picture-wrapper-vertical-row-white-space"]}
        />
      </div>
    </section>
  );
};

export default Gym;
