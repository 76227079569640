import styles from "./index.module.scss";
import clock from "../../assets/icons/clock.svg";
import pen from "../../assets/icons/pen.svg";
import flag from "../../assets/icons/flag.svg";
import guy from "../../assets/images/summer-program.jpg";
import downArrow from "../../assets/icons/down-arrow.svg";
import RegisterButton from "../RegisterButton";

const OpeningTimes = () => {
  const content = [
    { text: "From Saturday  to Thursday From 6 am to 8 pm", icon: clock },
    {
      text: "No booking is required. Please just fill our registration for either online or at the facility.",
      icon: pen,
    },
    {
      text: "During Peak times, for health, safety and fun, numbers will be capped and sessions may be reduced to 1-hour sessions.",
      icon: flag,
    },
  ];
  return (
    <section className={styles["opening-times-wrapper"]} id="summer-program">
      <div className={styles["opening-times-wrapper-vertical-row"]}>
        <hr />
        <div
          className={`circle ${styles["opening-times-wrapper-vertical-row-circle"]}`}
        />
        <p>summer program</p>
      </div>
      <div className={styles["opening-times-wrapper-content"]}>
        <h1>
          opening <br /> times
        </h1>
        <div className={styles["opening-times-wrapper-content-list"]}>
          {content.map((item, index) => (
            <div
              key={index}
              className={styles["opening-times-wrapper-content-list-item"]}
            >
              <img src={item.icon} alt="summer program" />
              <p>{item.text}</p>
            </div>
          ))}
        </div>
        <img src={guy} alt="summer program" />
      </div>
      <div  className={styles["opening-times-wrapper-register-btn-container"]}>
        <RegisterButton
          stylingClassName={
            styles["opening-times-wrapper-register-btn-container-btn"]
          }
        >
          <p>register now</p> <img src={downArrow} alt="" />
        </RegisterButton>
      </div>
    </section>
  );
};

export default OpeningTimes;
