import downArrow from "../../assets/icons/down-arrow.svg";
import rightArrow from "../../assets/icons/right-arrow.svg";
import bigRamyVideo from "../../assets/videos/big-ramy.mp4";
import RegisterButton from "../RegisterButton";
import styles from "./index.module.scss";
import { useState } from "react";

const Hero = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <section className={styles["hero"]}>
      <div className="yellow-overlay" />
      <video loop autoPlay muted>
        <source src={bigRamyVideo} type="video/mp4" />
      </video>
      <div className={styles["hero-main"]}>
        <h3>Join the Elite at Big Ramy Beachside Fitness</h3>

      </div>
      <div className={styles["hero-line-row"]}>
        <hr className={styles["hero-line-row-horizontal-line"]} />

        <div className={`circle ${styles["circle"]}`} />

        <div className={styles["hero-line-row-text"]}>
          <p>
            Our Beachside Gym has been designed to accommodate all your fitness
            needs
          </p>
          <a href="/#program" className={styles["btn"]}>Read More</a>
        </div>
      </div>
      <div className={styles["hero-vertical-row"]}>
        <hr className={styles["hero-vertical-row-line"]} />
        <a href="/#summer-program" className={styles["btn"]}>Summer Program</a>
        <div className={`circle ${styles["circle"]}`} />
        <a href="/about-big-ramy" className={styles["btn"]}>about big ramy</a>
        <RegisterButton
          stylingClassName={styles["hero-vertical-row-register-btn"]}
        >
          <p>register now</p> <img src={downArrow} alt="" />
        </RegisterButton>
      </div>
      <RegisterButton
        stylingClassName={styles["hero-responsive-register-btn"]}
      >
        <p>register now</p> <img src={rightArrow} alt="" />
      </RegisterButton>
    </section>
  );
};

export default Hero;
