import "./App.css";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Snackbar from './components/snackbar';
import { useRef, useState } from 'react';
import Register from "./pages/register";
import AboutBigRamy from "./pages/aboutBigRamy";

function App() {
  const contactRef = useRef(null);
  const [snackbar, setSnackbar] = useState({
    display: false,
    message: '',
    variant: 'success',
  });
  return (
    <>
      <Helmet>
        <title>BRCLUB | BEACHSIDE FITNESS</title>
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register setSnackbar={setSnackbar} />} />
          <Route path="/about-big-ramy" element={<AboutBigRamy/>} />
        </Routes>
      </BrowserRouter>
      <Snackbar snackbar={snackbar} setSnackbar={setSnackbar} />
    </>
  );
}

export default App;
