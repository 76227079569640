import styles from "./index.module.scss";
import brClubEliteRes from "../../assets/images/br-club-elite-responsive.png";
import brClubEliteFirst from "../../assets/images/br-club-elite-1.jpg";
import brClubEliteSecond from "../../assets/images/br-club-elite-2.jpg";
import brClubEliteThird from "../../assets/images/br-club-elite-3.jpg";

const BrClubElite = () => {
  return (
    <section className={styles["elite-wrapper"]} >
      <img src={brClubEliteRes} className={styles["elite-wrapper-img-res"]} />
      <div className={styles["elite-wrapper-images-container"]}>
        <img src={brClubEliteFirst} />
        <img src={brClubEliteSecond} />
        <img src={brClubEliteThird} />
      </div>
    </section>
  );
};

export default BrClubElite;
