import styles from "./index.module.scss";

const RegisterButton = ({ title, style, children, stylingClassName }) => {
  return (
    <a href="/register" style={style} className={`${styles["btn"]} ${stylingClassName}`}>
      {title ? title : children}
    </a>
  );
};

export default RegisterButton;
